export const productsCarouselConfig = {
    createElements: true,
    slidesPerView: 2,
    spaceBetween: 8,
    breakpoints: {
        640: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 3.1,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 16,
        }
    },
}